<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" :propsParam="propsParam">
      <div slot="content-buttons">
        <Button _key="btnChargingCreate" title="Cobrança" classIcon="fas fa-plus-circle" type="primary" size="small"
          eventName="chargingCreate" :clicked="create" />
      </div>
      <div slot="content-filter-horizontal">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="3" xl="3">
            <SelectStatic title="Situação" :initialValue="select" :initialFieldTarget="select" fieldTarget="Status"
              :showBtnClean="true" :data="options" :markFormDirty="true" v-model="select" />
          </b-col>
        </b-row>
      </div>
    </ViewTemplateWithTable>
  </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import SelectStatic from "@nixweb/nixloc-ui/src/component/forms/SelectStatic";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ChargingListView",
  components: {
    ViewTemplateWithTable,
    Button,
    SelectStatic
  },
  data() {
    return {
      options: [
        { content: 'Bloquear', id: 'Bloquear' },
        { content: 'Negativar', id: 'Negativar' },
        { content: 'Bloqueado', id: 'Bloqueado' },
        { content: 'Negativado', id: 'Negativado' },
        { content: 'Em atraso', id: 'Em atraso' },
        { content: 'Em dia', id: 'Em dia' },
      ],
      panel: {
        module: "Cobrança",
        title: "Cobrança",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/billing/charging/get-all",
        urlDeleteAllApi: "/api/v1/billing/charging/delete",
        showChecks: true,
        headerTable: [
          {
            field: "customerSupplierName",
            title: "Cliente",
            type: "link",
            styleBody: "max-width: 600px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;",
            routeName: "chargingUpdate",
            iconSearch: true,
          },
          {
            field: "companyName",
            title: "Id Vinculado",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            title: "V.Recorrência",
            field: "totalRecurringActive",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },
          {
            field: "totalRecurringName",
            title: "Recorrência",
            type: "class",
            fieldComparison: "totalRecurringName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-info",
                fieldComparison: "Ativa",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Inativa",
              },
            ],
          },
          {
            field: "blockedName",
            title: "",
            type: "class",
            fieldComparison: "blockedName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-warning",
                fieldComparison: "Bloquear",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Bloqueado",
              },
            ],
          },

          {
            field: "negativeName",
            title: "",
            type: "class",
            fieldComparison: "negativeName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-warning",
                fieldComparison: "Negativar",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Negativado",
              },
            ],
          },
          {
            field: "status",
            title: "Situação",
            type: "class",
            fieldComparison: "status",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Em atraso",
              },
              {
                classCss: "text-center badge badge-success",
                fieldComparison: "Em dia",
              },
            ],
          },
          {
            title: "",
            field: "warning",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "html",
          },
        ],
      },
      propsParam: {
        filter: "",
        status: ""
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    create() {
      this.$router.push({
        name: "chargingCreate",
      });
      this.removeLoading(["btnChargingCreate"]);
    },
  },
};
</script>
<style>
.total-recurring-active {
  color: darkblue;
}

.total-recurring-disabled {
  color: red;
}

.div-tag {
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>